<template>
  <div
    id="app"
    class="relative flex justify-center min-h-screen px-6 pb-6 font-medium text-white bg-cover bg-gradient-to-tr from-gray-900 to-gray-800 selection:bg-blue-600"
  >
    <div class="w-full max-w-3xl mt-6" v-if="checkingAuth === false">
      <button
        class="px-3 py-1 flex items-center space-x-2 text-sm font-semibold text-blue-500 transition rounded-full hover:bg-gray-800"
        @click="logOut"
        :class="{ 'opacity-0': loggedIn === false }"
      >
        <svg
          class="w-4 h-4 rotate-180"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 16.9999L21 11.9999M21 11.9999L16 6.99994M21 11.9999H9M12 16.9999C12 17.2955 12 17.4433 11.989 17.5713C11.8748 18.9019 10.8949 19.9968 9.58503 20.2572C9.45903 20.2823 9.31202 20.2986 9.01835 20.3312L7.99694 20.4447C6.46248 20.6152 5.69521 20.7005 5.08566 20.5054C4.27293 20.2453 3.60942 19.6515 3.26118 18.8724C3 18.2881 3 17.5162 3 15.9722V8.02764C3 6.4837 3 5.71174 3.26118 5.12746C3.60942 4.34842 4.27293 3.75454 5.08566 3.49447C5.69521 3.29941 6.46246 3.38466 7.99694 3.55516L9.01835 3.66865C9.31212 3.70129 9.45901 3.71761 9.58503 3.74267C10.8949 4.0031 11.8748 5.09798 11.989 6.42855C12 6.55657 12 6.70436 12 6.99994"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        <span>Log uit</span>
      </button>
      <div class="mt-8 text-2xl font-extrabold text-center text-white">
        Rivasono Teamleader-Knack Bridge
      </div>

      <div class="max-w-md mx-auto mt-16" v-show="loggedIn === false">
        <div class="text-lg font-bold text-white">Log in</div>
        <form @submit.prevent="logIn">
          <label for="email" class="block mt-4">
            <span class="text-sm font-semibold text-gray-400"
              >E-mail adres</span
            >
            <div
              class="relative block transition mt-1 bg-gray-800 border-2 border-gray-600 rounded focus-within:border-blue-500 before:content-[''] before:absolute before:inset-0 before:bg-blue-600 before:blur before:opacity-0 before:transition-opacity focus-within:before:opacity-75"
            >
              <div class="absolute inset-0 z-0 bg-gray-800 rounded"></div>
              <input
                class="relative z-10 block w-full px-3 py-3 text-sm font-semibold text-white bg-transparent focus:outline-none"
                type="email"
                name="email"
                id="email"
                v-model="loginEmail"
                placeholder="naam@rivasono.nl"
              />
            </div>
          </label>
          <p class="h-3 mt-2 text-xs font-semibold text-red-400">
            {{ emailFieldError }}
          </p>
          <label for="password" class="block mt-3">
            <span class="text-sm font-semibold text-gray-400">Wachtwoord</span>
            <div
              class="relative block transition mt-1 bg-gray-800 border-2 border-gray-600 rounded focus-within:border-blue-500 before:content-[''] before:absolute before:inset-0 before:bg-blue-600 before:blur before:opacity-0 before:transition-opacity focus-within:before:opacity-75"
            >
              <div class="absolute inset-0 z-0 bg-gray-800 rounded"></div>
              <input
                class="relative z-10 block w-full px-3 py-3 text-sm font-semibold text-white bg-transparent focus:outline-none"
                type="password"
                name="password"
                id="password"
                v-model="loginPassword"
                placeholder="********"
              />
            </div>
          </label>
          <p class="h-3 mt-2 text-xs font-semibold text-red-400">
            {{ passwordFieldError }}
          </p>
          <base-button class="mt-6" @click="logIn" :busy="loggingIn">
            <svg
              class="w-4 h-4"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 17C6 17.3513 6 17.5269 6.01567 17.6796C6.14575 18.9474 7.0626 19.9946 8.30206 20.2911C8.45134 20.3268 8.6255 20.35 8.97368 20.3965L15.5656 21.2754C17.442 21.5256 18.3803 21.6507 19.1084 21.3611C19.7478 21.1069 20.2803 20.6407 20.6168 20.0406C21 19.357 21 18.4105 21 16.5175V7.48244C21 5.5894 21 4.64288 20.6168 3.95935C20.2803 3.35923 19.7478 2.893 19.1084 2.6388C18.3803 2.34926 17.442 2.47435 15.5656 2.72455L8.97368 3.60347C8.62546 3.6499 8.45135 3.67311 8.30206 3.70883C7.0626 4.00532 6.14575 5.05254 6.01567 6.3203C6 6.47301 6 6.64866 6 6.99996M12 7.99996L16 12M16 12L12 16M16 12H3"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Log in</span>
          </base-button>
        </form>
      </div>

      <div class="mt-16" v-show="loggedIn === true">
        <div>
          <label
            for="url"
            class="relative block transition bg-gray-800 border-2 border-gray-600 rounded focus-within:border-blue-500 before:content-[''] before:absolute before:inset-0 before:bg-blue-600 before:blur before:opacity-0 before:transition-opacity focus-within:before:opacity-75"
          >
            <div class="absolute inset-0 z-0 bg-gray-800 rounded"></div>
            <span
              class="absolute left-0 ml-3 text-sm font-semibold text-gray-400 -translate-y-1/2 top-1/2"
              >Deal-url:</span
            >
            <input
              class="relative inset-0 z-10 block w-full py-3 pl-20 pr-3 text-sm font-semibold text-white bg-transparent focus:outline-none"
              type="text"
              name="url"
              id="url"
              v-model="dealUrl"
            />
            <span
              class="absolute right-0 z-20 pl-3 mr-3 font-semibold text-green-500 transition-opacity -translate-y-1/2 bg-gray-800 pointer-events-none top-1/2"
              :class="{
                'opacity-0': dealUrlIsValid === false,
              }"
            >
              <svg
                class="w-5 h-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 12L10.5 15L16.5 9M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
          </label>
        </div>
        <div class="flex justify-center mt-4">
          <base-button
            class="mt-4"
            @click="startFetchChain"
            :busy="busy || bridging"
          >
            <svg
              class="w-5 h-5"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 17L12 21M12 21L16 17M12 21V12M20 16.7428C21.2215 15.734 22 14.2079 22 12.5C22 9.46243 19.5376 7 16.5 7C16.2815 7 16.0771 6.886 15.9661 6.69774C14.6621 4.48484 12.2544 3 9.5 3C5.35786 3 2 6.35786 2 10.5C2 12.5661 2.83545 14.4371 4.18695 15.7935"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <span>Deal ophalen</span>
          </base-button>
        </div>
        <div class="mt-12" v-if="busy">
          <div class="h-4 bg-gray-600 rounded-full">
            <div
              class="relative h-full transition"
              :class="{
                hidden: fetchingStatus === 'fetchingAccessToken',
                'w-1/5': fetchingStatus === 'fetchingDeal',
                'w-2/5': fetchingStatus === 'fetchingSalesRep',
                'w-3/5': fetchingStatus === 'fetchingClient',
                'w-4/5': fetchingStatus === 'fetchingQuotations',
                'w-full': fetchingStatus === 'done',
              }"
            >
              <div
                class="absolute inset-0 bg-blue-600 rounded-full opacity-75 blur"
              ></div>
              <div
                class="h-full bg-blue-600 border-2 border-blue-500 rounded-full"
              ></div>
            </div>
          </div>
          <div class="flex justify-center mt-3">
            <div
              class="text-sm text-gray-400"
              v-if="fetchingStatus === 'fetchingAccessToken'"
            >
              Contact maken met Teamleader...
            </div>
            <div
              class="text-sm text-gray-400"
              v-if="fetchingStatus === 'fetchingDeal'"
            >
              Dealgegevens ophalen...
            </div>
            <div
              class="text-sm text-gray-400"
              v-if="fetchingStatus === 'fetchingSalesRep'"
            >
              Sales medewerker ophalen...
            </div>
            <div
              class="text-sm text-gray-400"
              v-if="fetchingStatus === 'fetchingClient'"
            >
              Klantgegevens ophalen...
            </div>
            <div
              class="text-sm text-gray-400"
              v-if="fetchingStatus === 'fetchingQuotations'"
            >
              Offerte(s) ophalen...
            </div>
            <div class="text-sm text-gray-400" v-if="fetchingStatus === 'done'">
              Klaar!
            </div>
          </div>
        </div>
        <div class="mt-8" v-if="fetchingStatus === 'done'">
          <div class="px-5 py-4 bg-gray-800 divide-y divide-gray-600 rounded">
            <div class="pb-5 font-bold text-white">
              <div>Deal {{ deal.data.reference }}</div>
              <div class="mt-1 text-sm font-semibold text-gray-300">
                {{ deal.data.title }}
              </div>
            </div>
            <div class="flex py-4 space-x-8 text-sm">
              <div>
                <div class="text-gray-300">Aangemaakt</div>
                <div class="mt-2 font-bold text-white">
                  {{ deal.data.created_at | cleanDate }}
                </div>
              </div>
              <div>
                <div class="text-gray-300">Aanvaard</div>
                <div class="mt-2 font-bold text-white">
                  {{ deal.data.closed_at | cleanDate }}
                </div>
              </div>
            </div>
            <div class="py-4">
              <div class="text-sm text-gray-300">Klant</div>
              <div
                class="mt-2 space-y-1 text-sm font-bold text-white"
                v-if="dealContact && dealLead"
              >
                <div>{{ dealLead.data.name }}</div>
                <div>
                  {{ dealContact.data.first_name }}
                  {{ dealContact.data.last_name }}
                </div>
                <div v-if="dealContact.data.telephones[0]">
                  {{ dealContact.data.telephones[0].number }}
                </div>
                <div
                  v-if="
                    !dealContact.data.telephones[0] &&
                      dealLead.data.telephones[0]
                  "
                >
                  {{ dealLead.data.telephones[0].number }}
                </div>
                <div v-if="dealContact.data.emails[0]">
                  {{ dealContact.data.emails[0].email }}
                </div>
                <div
                  v-if="!dealContact.data.emails[0] && dealLead.data.emails[0]"
                >
                  {{ dealLead.data.emails[0].email }}
                </div>
              </div>
              <div
                class="mt-2 space-y-1 text-sm font-bold text-white"
                v-if="dealLead && dealContact === null"
              >
                <div>
                  {{ dealLead.data.first_name }}
                  {{ dealLead.data.last_name }}
                </div>
                <div v-if="dealLead.data.telephones[0]">
                  {{ dealLead.data.telephones[0].number }}
                </div>
                <div v-if="dealLead.data.emails[0]">
                  {{ dealLead.data.emails[0].email }}
                </div>
              </div>
            </div>
            <div class="py-4">
              <div class="text-sm font-semibold text-gray-300">Offertes</div>
              <div class="grid grid-cols-3 mt-3">
                <div class="pl-3 text-sm font-semibold text-gray-300">Naam</div>
                <div class="px-3 text-sm font-semibold text-gray-300">
                  Status
                </div>
                <div
                  class="pr-3 text-sm font-semibold text-right text-gray-300"
                >
                  Bedrag
                </div>
              </div>
              <div class="mt-2 space-y-2">
                <div
                  class="grid items-center grid-cols-3 px-4 py-3 font-bold text-white bg-gray-700 rounded"
                  v-for="(quotation, index) in dealQuotations"
                  :key="quotation.data.id"
                >
                  <div class="text-sm">
                    Offerte {{ deal.data.reference
                    }}<span v-if="index > 0">-{{ index }}</span>
                  </div>
                  <div>
                    <div
                      class="inline-block px-2 py-1 text-xs rounded-full"
                      :class="{
                        'bg-blue-600 text-blue-100':
                          quotation.data.status === 'open',
                        'bg-green-600 text-green-100':
                          quotation.data.status === 'accepted',
                        'bg-red-600 text-red-100':
                          quotation.data.status === 'lost',
                      }"
                    >
                      <span v-if="quotation.data.status === 'open'">Open</span>
                      <span v-if="quotation.data.status === 'accepted'"
                        >Aanvaard</span
                      >
                      <span v-if="quotation.data.status === 'lost'"
                        >Geweigerd</span
                      >
                    </div>
                  </div>
                  <div class="text-sm text-right">
                    €
                    {{ quotation.data.total.tax_inclusive.amount.toFixed(2) }}
                    <span class="text-gray-400">incl. BTW</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center mt-8">
            <base-button
              @click="startBridging"
              :busy="bridging"
              v-if="wonQuotation"
            >
              <svg
                class="w-5 h-5"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 16L12 12M12 12L16 16M12 12V21M20 16.7428C21.2215 15.734 22 14.2079 22 12.5C22 9.46243 19.5376 7 16.5 7C16.2815 7 16.0771 6.886 15.9661 6.69774C14.6621 4.48484 12.2544 3 9.5 3C5.35786 3 2 6.35786 2 10.5C2 12.5661 2.83545 14.4371 4.18695 15.7935"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>Aanvaarde offerte naar Knack overbrengen</span>
            </base-button>
            <div class="text-sm font-semibold text-gray-400" v-else>
              Deze deal heeft geen aanvaarde offerte
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="absolute inset-0 flex items-center justify-center"
      v-if="checkingAuth"
    >
      <div class="flex flex-col items-center text-2xl text-white">
        <div>
          <svg
            class="animate-spin w-5 h-5"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="mt-2 text-sm text-gray-400">Bridge is aan het laden...</div>
      </div>
    </div>
    <div
      class="absolute inset-0 z-30 flex items-center justify-center transition-opacity bg-black bg-opacity-60"
      :class="{
        'opacity-1': showBridgeProcess,
        'opacity-0 pointer-events-none': !showBridgeProcess,
      }"
    >
      <div class="px-5 bg-gray-800 rounded w-80">
        <div class="mt-4 font-bold">Bridge proces</div>
        <div class="h-4 mt-6 bg-gray-600 rounded-full">
          <div
            class="relative h-full transition"
            v-show="currentlyBridgedProducts > 0"
            :style="{
              width: `${(currentlyBridgedProducts / productsToBridge) * 100}%`,
            }"
          >
            <div
              class="absolute inset-0 bg-blue-600 rounded-full opacity-75 blur"
            ></div>
            <div
              class="h-full bg-blue-600 border-2 border-blue-500 rounded-full"
            ></div>
          </div>
        </div>
        <div class="mt-2 text-sm text-green-500">
          {{ currentlyBridgedProducts }} van
          {{ wonQuotationProductsAmount }} product(en) gebridged
        </div>
        <base-button
          class="w-full mt-6 mb-5"
          @click="showBridgeProcess = false"
          :busy="bridging"
          >Ok</base-button
        >
      </div>
    </div>
    <div
      class="absolute inset-0 z-30 flex items-center justify-center transition-opacity bg-black bg-opacity-60"
      :class="{
        'opacity-1': showError,
        'opacity-0 pointer-events-none': !showError,
      }"
    >
      <div class="w-64 px-4 bg-gray-800 rounded">
        <div class="mt-4 font-bold">Bridge fout</div>
        <div class="mt-2 text-sm text-yellow-500">
          Controleer Knack of de order juist is overgezet.
        </div>
        <base-button class="w-full mt-4 mb-4" @click="showError = false"
          >Ok</base-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { auth, db } from "./firebase.js";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { doc, getDoc, setDoc } from "firebase/firestore";

import BaseButton from "./components/BaseButton";

export default {
  name: "App",
  components: { BaseButton },
  data() {
    return {
      emailFieldError: "",
      passwordFieldError: "",
      checkingAuth: true,
      loggedIn: false,
      loggingIn: false,
      loginEmail: "",
      loginPassword: "",
      dealUrl: "",
      fetchingStatus: "ready",
      TeamleaderAccessToken: null,
      TeamleaderRefreshToken: null,
      deal: null,
      salesRep: null,
      dealLead: null,
      dealContact: null,
      dealQuotations: null,
      bridging: false,
      productsToBridge: 43,
      currentlyBridgedProducts: 0,
      bridgedProductsAmount: 0,
      showBridgeProcess: false,
      showError: false,
    };
  },
  computed: {
    busy() {
      return this.fetchingStatus !== "ready" && this.fetchingStatus !== "done";
    },
    dealUrlIsValid() {
      const urlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
      return urlRegex.test(this.dealUrl);
    },
    dealId() {
      const splitDealUrl = this.dealUrl.split("/");
      return splitDealUrl[splitDealUrl.length - 1];
    },
    wonQuotation() {
      if (this.dealQuotations) {
        return this.dealQuotations.find(
          quotation => quotation.data.status === "accepted"
        );
      } else {
        return null;
      }
    },
    wonQuotationProductsAmount() {
      if (this.wonQuotation) {
        let total = 0;
        this.wonQuotation.data.grouped_lines.forEach(group => {
          group.line_items.forEach(() => {
            total += 1;
          });
        });
        return total;
      } else {
        return 0;
      }
    },
  },
  methods: {
    async startFetchChain() {
      this.fetchingStatus = "fetchingAccessToken";

      const accessAndRefreshToken = await this.fetchAccessAndRefreshToken();
      const newAccessAndRefreshToken = await this.fetchNewRefreshAndAccessToken(
        accessAndRefreshToken.refreshToken
      );
      this.TeamleaderAccessToken = newAccessAndRefreshToken.newAccessToken;
      this.TeamleaderRefreshToken = newAccessAndRefreshToken.newRefreshToken;
      await this.writeNewAccessAndRefreshToken();

      this.fetchingStatus = "fetchingDeal";
      this.deal = await this.fetchDeal(newAccessAndRefreshToken.newAccessToken);

      this.fetchingStatus = "fetchingSalesRep";
      if (this.deal.data.responsible_user) {
        this.salesRep = await this.fetchSalesRep(
          this.deal.data.responsible_user.id
        );
      }

      this.fetchingStatus = "fetchingClient";
      const lead = this.deal.data.lead;
      if (lead.customer.type === "contact") {
        this.dealLead = await this.fetchDealContact(lead.customer.id);
        this.dealContact = null;
      } else if (this.deal.data.lead.customer.type === "company") {
        this.dealLead = await this.fetchDealBusiness(lead.customer.id);
        this.dealContact = await this.fetchDealContact(lead.contact_person.id);
      }

      this.fetchingStatus = "fetchingQuotations";
      this.dealQuotations = await Promise.all(
        this.deal.data.quotations.map(this.fetchQuotation)
      );

      this.fetchingStatus = "done";
    },
    async fetchDeal() {
      return await fetch("https://api.focus.teamleader.eu/deals.info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TeamleaderAccessToken}`,
        },
        body: JSON.stringify({
          id: this.dealId,
        }),
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      });
    },
    async fetchSalesRep(salesRepId) {
      return await fetch("https://api.focus.teamleader.eu/users.info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TeamleaderAccessToken}`,
        },
        body: JSON.stringify({
          id: salesRepId,
        }),
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      });
    },
    async fetchDealContact(contactId) {
      return await fetch("https://api.focus.teamleader.eu/contacts.info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TeamleaderAccessToken}`,
        },
        body: JSON.stringify({
          id: contactId,
        }),
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      });
    },
    async fetchDealBusiness(businessId) {
      return await fetch("https://api.focus.teamleader.eu/companies.info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TeamleaderAccessToken}`,
        },
        body: JSON.stringify({
          id: businessId,
        }),
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      });
    },
    async fetchQuotation(quotation) {
      return await fetch("https://api.focus.teamleader.eu/quotations.info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.TeamleaderAccessToken}`,
        },
        body: JSON.stringify({
          id: quotation.id,
        }),
      }).then(res => {
        if (res.ok) {
          return res.json();
        }
      });
    },
    async fetchAccessAndRefreshToken() {
      const tokenFetches = [
        this.fetchDocument("bridge", "access_token"),
        this.fetchDocument("bridge", "refresh_token"),
      ];
      return Promise.all(tokenFetches).then(tokenDocs => {
        return {
          accessToken: tokenDocs[0].data().value,
          refreshToken: tokenDocs[1].data().value,
        };
      });
    },
    async fetchKnackAPIKeyAndApplicationId() {
      const fetches = [
        this.fetchDocument("bridge", "knack-api-key"),
        this.fetchDocument("bridge", "knack-application-id"),
        this.fetchDocument("bridge", "DEV-knack-api-key"),
        this.fetchDocument("bridge", "DEV-knack-application-id"),
      ];
      return Promise.all(fetches).then(docs => {
        return {
          knackAPIKey: docs[0].data().value,
          knackApplicationID: docs[1].data().value,
          DEV_knackAPIKey: docs[2].data().value,
          DEV_knackApplicationID: docs[3].data().value,
        };
      });
    },
    async fetchNewRefreshAndAccessToken(refreshToken) {
      return await fetch("/.netlify/functions/TeamleaderAPI", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          instruction: "getNewRefreshAndAccessToken",
          refreshToken,
        }),
      }).then(response => {
        if (response.ok) {
          return response.json();
        }
      });
    },
    async writeNewAccessAndRefreshToken() {
      const tokenWrites = [
        this.writeDocument("bridge", "access_token", {
          value: this.TeamleaderAccessToken,
        }),
        this.writeDocument("bridge", "refresh_token", {
          value: this.TeamleaderRefreshToken,
        }),
      ];
      return Promise.all(tokenWrites);
    },
    async startBridging() {
      if (this.wonQuotation) {
        this.bridging = true;

        let knackOrderObject = {
          field_6: this.currentDateToKnackDate(), // Orderdatum
          field_9: "?", // Montage
          field_31: this.deal.data.summary || "", // Opmerkingen
          field_176:
            `${this.salesRep.data.first_name} ${this.salesRep.data.last_name}` ||
            "", // Sales eigenaar
          field_222: this.dealUrl, // TL deal link
          field_262: this.deal.data.title, // Dealtitel
          field_387: this.deal.data.reference, // TL deal_nr
          field_393: this.convertTLDateToKnackDate(this.deal.data.created_at),
        };

        // Als de deal zakelijk is
        if (this.dealContact && this.dealLead) {
          knackOrderObject = {
            ...knackOrderObject,
            field_2: this.dealLead.data.name, // Klantnaam
            field_231: `${this.dealContact.data.first_name} ${this.dealContact.data.last_name}`,
          };

          if (this.dealContact.data.telephones.length > 0) {
            knackOrderObject.field_4 = this.dealContact.data.telephones[0].number;
          } else if (this.dealLead.data.telephones.length > 0) {
            knackOrderObject.field_4 = this.dealLead.data.telephones[0].number;
          }

          if (this.dealContact.data.emails.length > 0) {
            knackOrderObject.field_229 = this.dealContact.data.emails[0].email;
          } else if (this.dealLead.data.emails.length > 0) {
            knackOrderObject.field_229 = this.dealLead.data.emails[0].email;
          }

          // Als de deal particulier is
        } else {
          knackOrderObject = {
            ...knackOrderObject,
            field_2: `${this.dealLead.data.first_name} ${this.dealLead.data.last_name}`, // Klantnaam
            field_231: `${this.dealLead.data.first_name} ${this.dealLead.data.last_name}`,
          };

          if (this.dealLead.data.telephones.length > 0) {
            knackOrderObject.field_4 = this.dealLead.data.telephones[0].number;
          }

          if (this.dealLead.data.emails.length > 0) {
            knackOrderObject.field_229 = this.dealLead.data.emails[0].email;
          }
        }

        // Zoeken naar een speciaal leveradres
        const specialDeliveryAddress = this.dealLead.data.addresses.find(
          address => {
            return address.type === "delivery";
          }
        );

        // Als er een speciaal leveradres gevonden is
        if (specialDeliveryAddress) {
          knackOrderObject.field_3 = {
            // Adres
            street: specialDeliveryAddress.address.line_1,
            city: specialDeliveryAddress.address.city,
            zip: specialDeliveryAddress.address.postal_code,
          };
          // Als er geen speciaal leveradres gevonden is, maar wel een adres
        } else if (this.dealLead.data.addresses.length > 0) {
          knackOrderObject.field_3 = {
            street: this.dealLead.data.addresses[0].address.line_1,
            city: this.dealLead.data.addresses[0].address.city,
            zip: this.dealLead.data.addresses[0].address.postal_code,
          };
        }

        /*
        const sendProductsToKnackResult = await fetch(
          "/.netlify/functions/sendProductsToKnack",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              knackOrderObject,
              wonQuotation: this.wonQuotation,
            }),
          }
        )
          .then(response => {
            if (response.ok) {
              return response.json();
            }
          })
          .catch(err => {
            console.log(err);
            this.bridging = false;
            this.showError = true;
          });
        */

        console.log(knackOrderObject);

        this.currentlyBridgedProducts = 0;
        this.showBridgeProcess = true;

        const knackAPIKeyAndApplicationId = await this.fetchKnackAPIKeyAndApplicationId();

        const sendProductsToKnackResult = await this.sendProductsToKnack(
          knackAPIKeyAndApplicationId,
          knackOrderObject,
          this.wonQuotation
        );

        console.log(sendProductsToKnackResult);

        this.bridging = false;
      }
    },
    async sendProductsToKnack(
      knackAPIKeyAndApplicationId,
      knackOrderObject,
      wonQuotation
    ) {
      const knackAPIKey =
        process.env.NODE_ENV === "development"
          ? knackAPIKeyAndApplicationId.DEV_knackAPIKey
          : knackAPIKeyAndApplicationId.knackAPIKey;
      const knackApplicationID =
        process.env.NODE_ENV === "development"
          ? knackAPIKeyAndApplicationId.DEV_knackApplicationID
          : knackAPIKeyAndApplicationId.knackApplicationID;

      const order = await fetch(
        "https://api.knack.com/v1/objects/object_1/records",
        {
          method: "POST",
          headers: {
            "X-Knack-Application-Id": knackApplicationID,
            "X-Knack-REST-API-KEY": knackAPIKey,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(knackOrderObject),
        }
      )
        .then(res => res.json())
        .catch(() => console.log);

      function timeout(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      }

      const orderProducts = [];
      wonQuotation.data.grouped_lines.forEach(group => {
        group.line_items.forEach(line => {
          if (line.description === "8 - MONTAGE: Montagewerkzaamheden") {
            orderProducts.push({
              field_156: "", // ID
              field_157: line.description, // Naam
              field_170: "5. n.v.t.", // Bestelstatus
              field_171: 1, // Aantal
              field_181: "", // Leverancier
              field_180: [order.id], // Order
              field_391:
                line.extended_description === null
                  ? "Prijs: €" + line.quantity * line.unit_price.amount
                  : line.extended_description +
                    ", Prijs: €" +
                    line.quantity * line.unit_price.amount, // Beschrijving
            });
          } else {
            orderProducts.push({
              field_156: "", // ID
              field_157: line.description, // Naam
              field_170: "1. te bestellen", // Bestelstatus
              field_171: line.quantity, // Aantal
              field_181: "", // Leverancier
              field_180: [order.id], // Order
              field_391: line.extended_description, // Beschrijving
            });
          }
        });
      });

      this.productsToBridge = orderProducts.length;

      const orderProductsPostRequests = [];
      orderProducts.forEach((product, index) => {
        orderProductsPostRequests.push(
          new Promise(async resolve => {
            await timeout(120 * index);
            console.log(index);
            const productResult = await fetch(
              "https://api.knack.com/v1/objects/object_8/records",
              {
                method: "POST",
                headers: {
                  "X-Knack-Application-Id": knackApplicationID,
                  "X-Knack-REST-API-KEY": knackAPIKey,
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(product),
              }
            )
              .then(() => {
                resolve(productResult);
                this.currentlyBridgedProducts++;
              })
              .catch(error => {
                console.log(error);
                this.showError = true;
                this.showBridgeProcess = false;
              });
          })
        );
      });

      await Promise.all(orderProductsPostRequests);

      return { productsAdded: orderProductsPostRequests.length };
    },
    convertTLDateToKnackDate(TLDate) {
      const year = TLDate.substring(0, 4);
      const month = TLDate.substring(5, 7);
      const day = TLDate.substring(8, 10);
      return `${day}/${month}/${year}`;
    },
    logOut() {
      signOut(auth)
        .then(() => {
          location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    },
    logIn() {
      this.loggingIn = true;
      this.emailFieldError = "";
      this.passwordFieldError = "";

      if (this.loginEmail === "") {
        this.emailFieldError = "Vul een valide e-mail adres in.";
        this.loggingIn = false;
      } else if (this.loginPassword === "") {
        this.passwordFieldError = "Vul een wachtwoord in.";
        this.loggingIn = false;
      } else {
        signInWithEmailAndPassword(auth, this.loginEmail, this.loginPassword)
          .then(userCredential => {
            this.loggingIn = false;
            this.loggedIn = true;
          })
          .catch(error => {
            if (error.code === "auth/wrong-password") {
              this.passwordFieldError = "E-mail of wachtwoord is incorrect";
            }
            this.loggingIn = false;
          });
      }
    },
    async writeDocument(collection, document, data) {
      return await setDoc(doc(db, collection, document), data);
    },
    async fetchDocument(collection, document) {
      return await getDoc(doc(db, collection, document));
    },
    currentDateToKnackDate() {
      const date = new Date();
      return (
        this.padDate(date.getDate()) +
        "/" +
        this.padDate(date.getMonth() + 1) +
        "/" +
        date.getFullYear()
      );
    },
    padDate(n) {
      return n < 10 ? "0" + n : n;
    },
  },
  filters: {
    cleanDate(value) {
      return new Date(value).toLocaleString("nl-NL", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
    },
  },
  mounted() {
    onAuthStateChanged(auth, user => {
      if (user) {
        this.loggedIn = true;
        this.checkingAuth = false;
      } else {
        this.checkingAuth = false;
      }
    });
  },
};
</script>
