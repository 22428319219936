var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"relative px-3 py-2 text-sm font-semibold text-white transition border-2 rounded shadow focus:outline-none before:content-[''] before:absolute before:inset-0 before:blur before:opacity-0 before:transition-opacity focus-visible:ring-2 ring-blue-500 ring-offset-2 ring-offset-gray-900",class:{
    'bg-blue-600 border-blue-500 before:bg-blue-600': _vm.color === 'blue',
    'hover:before:opacity-75 hover:bg-blue-700':
      _vm.color === 'blue' && _vm.busy === false,
    'cursor-pointer': _vm.busy === false,
    'cursor-wait': _vm.busy === true,
  },attrs:{"disabled":_vm.busy},on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"absolute inset-0 z-0 bg-blue-600 rounded"}),_c('div',{staticClass:"relative z-10 transition flex items-center space-x-2",class:{
      'opacity-0': _vm.busy === true,
    }},[(_vm.icon !== '')?_c('i',{class:_vm.icon}):_vm._e(),_vm._t("default")],2),_c('div',{staticClass:"absolute inset-0 flex items-center justify-center text-base transition",class:{
      'opacity-0': _vm.busy === false,
    }},[_c('svg',{staticClass:"animate-spin w-5 h-5",attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }