import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC2Cc5z-6ZihoEuyVHMwcfapiiG8qyg_mk",
  authDomain: "sonar-2.firebaseapp.com",
  databaseURL: "https://sonar-2.firebaseio.com",
  projectId: "sonar-2",
  storageBucket: "sonar-2.appspot.com",
  messagingSenderId: "893806588715",
  appId: "1:893806588715:web:d42eef4434e67ae41fcb6e",
  measurementId: "G-C9CF44ZK25",
};
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const db = getFirestore(app);

// export utils/refs
export { auth, db };
