import Vue from "vue";
import App from "./App.vue";
import { auth } from "./firebase.js";

Vue.config.productionTip = false;

import "./assets/tailwind.css";

let app;
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      render: h => h(App),
    }).$mount("#app");
  }
});
