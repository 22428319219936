<template>
  <button
    class="relative px-3 py-2 text-sm font-semibold text-white transition border-2 rounded shadow focus:outline-none before:content-[''] before:absolute before:inset-0 before:blur before:opacity-0 before:transition-opacity focus-visible:ring-2 ring-blue-500 ring-offset-2 ring-offset-gray-900"
    :class="{
      'bg-blue-600 border-blue-500 before:bg-blue-600': color === 'blue',
      'hover:before:opacity-75 hover:bg-blue-700':
        color === 'blue' && busy === false,
      'cursor-pointer': busy === false,
      'cursor-wait': busy === true,
    }"
    @click="$emit('click')"
    :disabled="busy"
  >
    <div class="absolute inset-0 z-0 bg-blue-600 rounded"></div>
    <div
      class="relative z-10 transition flex items-center space-x-2"
      :class="{
        'opacity-0': busy === true,
      }"
    >
      <i :class="icon" v-if="icon !== ''"></i><slot />
    </div>
    <div
      class="absolute inset-0 flex items-center justify-center text-base transition"
      :class="{
        'opacity-0': busy === false,
      }"
    >
      <svg
        class="animate-spin w-5 h-5"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2V6M12 18V22M6 12H2M22 12H18M19.0784 19.0784L16.25 16.25M19.0784 4.99994L16.25 7.82837M4.92157 19.0784L7.75 16.25M4.92157 4.99994L7.75 7.82837"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    busy: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: "blue",
    },
    glow: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: String,
      default: "",
    },
  },
};
</script>
